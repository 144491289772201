<template>
  <div>
    <div class="user-posting">
        <b-row>
          <b-col sm="12"><h5>Achievements</h5></b-col>
        </b-row>    
        <b-row>
          <b-col sm="8 mt-2"><p>Share your Achievement with us!</p></b-col>
          <b-col sm="4 mt-2"><b-button pill type="submit" variant="warning"><router-link :to="{name:'achievement'}">View All Achievements</router-link></b-button></b-col>

        </b-row>
    </div>    
    <b-col cols="12 my-4">      
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="input-Title" label-for="Title" class="col-8">
          <b-form-input
            id="Title"
            v-model="InpForm.Title"
            placeholder="Enter Title"
            required
          ></b-form-input>
        </b-form-group>

        <RefFormCatg :ShowMainCatg="true" @EmitMainCatg="SetMainCatg" :key="ComponentKey"/>

        <b-form-group class="col-8">
          <b-form-textarea
            id="textarea"
            size="md"
            v-model="InpForm.PostDesc"
            placeholder="Enter Short Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>  
        </b-form-group>

        <b-row>                  
          <b-col cols="5">         
            <b-button class="float-left ml-3" variant="success"  @click="click1">{{ButtonText}}
            </b-button>
            <input type="file" ref="input1"
              style="display: none"
              @change="previewImage" accept="image/*" >                                        
          </b-col>                  
        </b-row>

        <b-row>
          <b-col cols="12 my-3 ml-3" >
            <b-img v-if="ShowPhoto" class="float-left" rounded height="80" width="80" :src="PhotoPreview" >                 
            </b-img>
              <b-spinner v-else-if="ShowSpinner" small
                v-for="variant in variants"
                :variant="variant"
                :key="variant"
              ></b-spinner>                
          </b-col>  
        </b-row>
           
        <div cols="12" class="my-3 ml-3">
          <b-button pill type="submit" variant="primary">Submit</b-button>
          <b-button pill type="reset" variant="danger">Cancel</b-button>
        </div>
      </b-form>        
    </b-col>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'
import { mapGetters } from "vuex";


Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      ChildMainCatg: '',
      ComponentKey: 0,
      // Input form variables
      InpForm: {
        Title: '',
        PostType: 'default',
        PostCatg: 'default',
        PostDesc: null,
      },
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/achievement/',
      imageData: null,
      // Response array variables
      RespIns: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],
      // Select drop down options
      ListPostCatg: [
        { value: 'default', text: 'Select Category' },
        { value: 'Arts', text: 'Arts' },
        { value: 'Dance', text: 'Dance' },
        { value: 'Movies', text: 'Movies'},
      ],      
      // Other variables
      show: true,
      NextSeqNo: '',
      ShowSpinner: false,
      ShowPhoto: false,
      variants: ['primary', 'danger', 'warning'],
      ButtonText: 'Upload cover photo',
      }
    },
    mounted()
    {
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.GetMaxID()
      },

      SetMainCatg(event) {
          this.ChildMainCatg = event
          // console.log('ChildMainCatg',this.ChildMainCatg)
      },      

      GetMaxID(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'thira_user_postings');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetMaxID',REQformData)
        .then((resp)=>{
        this.RespGetMaxID=resp.data.GetMaxID;
        const FirstObject = this.RespGetMaxID[0];
        this.NextSeqNo = FirstObject.tbl_next_seq;
        // console.log('MaxSeqNo',this.NextSeqNo)
        this.InsUsrPostings()
        this.onUpload()
      })
      },      
          
      InsUsrPostings(){
        this.ComponentKey=0;
        const REQformData = new FormData();
        REQformData.append('seq_no', this.NextSeqNo);
        REQformData.append('post_type', 'Achievement');
        REQformData.append('post_title', this.InpForm.Title);
        REQformData.append('post_catg', this.ChildMainCatg);
        REQformData.append('post_desc', this.InpForm.PostDesc);
        REQformData.append('post_img', this.PhotoUrl + this.NextSeqNo + '/image1.jpg');

        // Logic to assign profile owner
        if (this.user.authenticated) {REQformData.append('created_by', this.user.data.email)}
        // else if (this.InpForm.Academy_email != '') {REQformData.append('created_by', this.InpForm.Academy_email)}
        else {{REQformData.append('created_by', 'info@thiraseela.com')}}

        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsUsrPostings',REQformData)
        .then((resp)=>{
          this.RespIns=resp.data.message;
          alert(this.RespIns)
          this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
      },
       
      ClearForms(){
       if (this.user.authenticated) {this.$router.replace('/user/dashboard')}
        this.InpForm.Title = '';
        this.InpForm.PostDesc = '';
        this.imageData=null;
        this.ShowSpinner=false;
        this.ShowPhoto=false;
        this.ComponentKey=1;
        this.ButtonText='Upload cover photo';
      },
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        this.ShowSpinner=true;
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        this.ButtonText='Submit to upload...'
      },

      onUpload(){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('achievement/' + this.NextSeqNo +'/' + 'image1.jpg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then(()=>{
                // this.ShowSpinner=false;
                // this.PhotoPreview =url;
                // this.ShowPhoto=true;
                // console.log(this.PhotoPreview)
              });
            }      
          );
       },
    },

  computed: {
    // map `this.user` to `this.$store.getters.user` 
    ...mapGetters({
      user: "user"
    })
    }   
  }
</script>

